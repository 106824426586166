import { useEffect } from 'react';
import { AssignmentServiceRoot } from 'src/apis/assignment-service/types';
import { useAxios, isServerError } from 'src/hooks/use-axios';
import { useToast } from 'src/hooks/use-toasts';
import { handleErrorWithNotification } from 'src/utils/handle-error';

export function useOptionalAssignmentService() {
  const notify = useToast();

  const assignmentService = useAxios(
    (axios, baseConfig) =>
      axios.request<AssignmentServiceRoot>({
        ...baseConfig,
        url: PRISMA_CONFIG.assignmentService,
        timeout: 5000,
      }),
    {
      neededOnPageLoad: true,
      cacheKey: 'assignment-service-root',
      onError(error) {
        if (!isServerError(error, 401)) {
          handleErrorWithNotification({
            error,
            notify,
            setReauthenticate: () => {},
          });
        }
        return error;
      },
    }
  );

  useEffect(() => {
    assignmentService.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return assignmentService;
}

export type OptionalAssignmentServiceRequest = ReturnType<
  typeof useOptionalAssignmentService
>;
