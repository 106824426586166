import { useEffect } from 'react';
import { AssignmentInfoResponse } from 'src/apis/assignment-service/types';
import { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import { Successful, useAxios } from 'src/hooks/use-axios';

export function useAssignmentInfo({
  assignmentService,
  operatorId,
}: {
  assignmentService: Successful<AssignmentServiceRequest>;
  operatorId: string;
}) {
  const publicAssignmentInfo = useAxios(
    (axios, baseConfig, url: string) =>
      axios.request<AssignmentInfoResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!assignmentService.response.data._links.assignmentInfo) return;

    const url =
      assignmentService.response.data._links.assignmentInfo.href.replace(
        '{organisationId}',
        operatorId
      );

    publicAssignmentInfo.execute(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!assignmentService.response.data._links.assignmentInfo) return;

  return publicAssignmentInfo;
}

export type AssignmentInfoRequest = ReturnType<typeof useAssignmentInfo>;
