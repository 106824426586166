import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect } from 'react';
import { Button } from 'src/components/buttons-and-actions/button';
import { Card } from 'src/components/data-display/card';
import { CardDivider } from 'src/components/dividers';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink } from 'src/components/navigation/link';
import { Heading } from 'src/components/text/heading';
import { useAxios } from 'src/hooks/use-axios';
import { setLocationHref } from 'src/utils/location-usage';
export const PublicRequiredDocumentsCard: FC<{
  gtcUrl: string;
  countryCode: string;
  registrationInformationDocumentUrl?: string;
  children: React.ReactNode;
}> = ({
  gtcUrl,
  countryCode,
  registrationInformationDocumentUrl,
  children,
}) => {
  return (
    <Card>
      <Stack gap={1}>
        <Heading mode="card">Documents</Heading>
        <CardDivider />

        <ExternalLink href={gtcUrl}>General Terms and Conditions</ExternalLink>
        <CardDivider />

        {countryCode === 'DE' && (
          <>
            <RegistrationInformationDocument
              documentUrl={registrationInformationDocumentUrl}
            />
            <CardDivider />
          </>
        )}

        {children}
      </Stack>
    </Card>
  );
};

export const RegistrationInformationDocument: FC<{
  documentUrl: string | undefined;
}> = ({ documentUrl }) => {
  const downloadDocument = useAxios(
    (axios, baseConfig, url: string) =>
      axios.request<{ downloadUrl: string; fileName: string }>({
        ...baseConfig,
        url,
        method: 'GET',
      }),
    { neededOnPageLoad: false }
  );

  useEffect(() => {
    if (!downloadDocument.response) return;
    setLocationHref(downloadDocument.response.data.downloadUrl);
  }, [downloadDocument.response]);

  return (
    <>
      <p>Following documents are available for download:</p>

      {documentUrl ? (
        <Button
          mode="link"
          onClick={() => downloadDocument.execute(documentUrl)}
          disabled={downloadDocument.pending}
        >
          <FontAwesomeIcon icon={faDownload} aria-hidden />{' '}
          {downloadDocument.pending ? 'Pending...' : 'Registration Information'}
        </Button>
      ) : (
        <p>
          Registration Information -{' '}
          <strong>
            Document is available only for logged in shipper users
          </strong>
        </p>
      )}
    </>
  );
};
