import { useEffect } from 'react';
import { RequiredDocumentsResponse } from 'src/apis/assignment-service/types';
import { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import { Successful, useAxios } from 'src/hooks/use-axios';

export function useOptionalPublicRequiredDocuments({
  assignmentService,
  operatorId,
}: {
  assignmentService: Successful<AssignmentServiceRequest>;
  operatorId: string;
}) {
  const publicRequiredDocuments = useAxios(
    (axios, baseConfig, url: string) =>
      axios.request<RequiredDocumentsResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!assignmentService.response.data._links.publicRequiredDocuments) return;

    const url =
      assignmentService.response.data._links.publicRequiredDocuments.href.replace(
        '{organisationId}',
        operatorId
      );
    publicRequiredDocuments.execute(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return publicRequiredDocuments;
}

export type OptionalRequiredDocumentsRequest = ReturnType<
  typeof useOptionalPublicRequiredDocuments
>;
